body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.wrapper {
  max-width: 1200px;
  margin: 2em auto;
}

.wrapper h1 {
  font-size: 2.2em !important;
  font-weight: bold !important;
}

.wrapper .tableWrapper > div {
  box-shadow: none;
}

.editOverlay {
  padding: 0 !important;
  background: transparent !important;
}
.editModal {
  position: fixed !important;

  right: 0;
  height: 100%;
  width: 50%;
}

.contentTableMap {
  margin-left: 80px;
}
